<template>
  <div class="order">
    <el-row :gutter="10" style="width: 100%">
      <el-col :lg="12" :md="24" :sm="24" :xl="12" :xs="12">
        <vab-card class="order-card1" shadow="hover">
          <template #header>
            <vab-icon icon="todo-line" />
            办个展申请
          </template>
          <el-row class="order-card1-content">
            <template v-for="(item, index) in applyExhData" :key="index">
              <el-col :span="item.colCount">
                <p>{{ item.name }}</p>
                <h1>
                  <vab-count
                    :decimals="item.countConfig.decimals"
                    :duration="item.countConfig.duration"
                    :end-val="item.countConfig.endVal"
                    :prefix="item.countConfig.prefix"
                    :separator="item.countConfig.separator"
                    :start-val="item.countConfig.startVal"
                    :suffix="item.countConfig.suffix"
                  />
                </h1>
              </el-col>
            </template>
          </el-row>
        </vab-card>
      </el-col>
      <el-col :lg="12" :md="24" :sm="24" :xl="12" :xs="12">
        <vab-card class="order-card2" shadow="hover">
          <template #header>
            <vab-icon icon="todo-line" />
            办个会申请
          </template>
          <el-row class="order-card2-content">
            <template v-for="(item, index) in applyMeetData" :key="index">
              <el-col :span="item.colCount">
                <p>{{ item.name }}</p>
                <h1>
                  <vab-count
                    :decimals="item.countConfig.decimals"
                    :duration="item.countConfig.duration"
                    :end-val="item.countConfig.endVal"
                    :prefix="item.countConfig.prefix"
                    :separator="item.countConfig.separator"
                    :start-val="item.countConfig.startVal"
                    :suffix="item.countConfig.suffix"
                  />
                </h1>
              </el-col>
            </template>
          </el-row>
        </vab-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { defineComponent, reactive, toRefs, watch } from 'vue'
  import _ from 'lodash'
  import VabCount from '@/extra/VabCount'

  export default defineComponent({
    components: { VabCount },
    props: {
      hostData: {
        type: Object,
        default: {},
      },
    },
    emits: ['confirm'],
    setup(props, { emit }) {
      const state = reactive({
        //  办个展数据
        applyExhData: [
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '个',
              separator: ',',
              duration: 3000,
            },
            name: '展览主办方',
            colCount: 8,
          },
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '次',
              separator: ',',
              duration: 3000,
            },
            name: '办个展申请数量',
            colCount: 8,
          },
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '次',
              separator: ',',
              duration: 3000,
            },
            name: '申请通过数量',
            colCount: 8,
          },
        ],
        //  办个会数据
        applyMeetData: [
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '个',
              separator: ',',
              duration: 3000,
            },
            name: '会议主办方',
            colCount: 8,
          },
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '次',
              separator: ',',
              duration: 3000,
            },
            name: '办个会申请数量',
            colCount: 8,
          },
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '次',
              separator: ',',
              duration: 3000,
            },
            name: '申请通过数量',
            colCount: 8,
          },
        ],
      
      })

      watch(
        () => props.hostData,
        (newVal, oldVal) => {
          console.log(newVal, oldVal, '123123123')
          state.applyExhData[0].countConfig.endVal = newVal.exhibitionHostCount
          state.applyExhData[1].countConfig.endVal = newVal.applyExhibitionCount
          state.applyExhData[2].countConfig.endVal =
            newVal.applyPassExhibitionCount
          state.applyMeetData[0].countConfig.endVal = newVal.meetingHostCount
          state.applyMeetData[1].countConfig.endVal = newVal.applyMeetingCount
          state.applyMeetData[2].countConfig.endVal =
            newVal.applyPassMeetingCount
        }
      )

      return {
        ...toRefs(state),
      }
    },
  })
</script>

<style lang="scss" scoped>
  .order {
    display: flex;
    justify-content: space-between;
    &-card1 {
      width: 100%;
      &-content {
        text-align: center;
      }

      :deep() {
        .el-card {
          &__header,
          &__body {
            color: var(--el-color-white) !important;
            background: linear-gradient(to right, #2cdd7d, #189765);
          }
        }
      }
    }

    &-card2 {
      width: 100%;
      &-content {
        text-align: center;
      }

      :deep() {
        .el-card {
          &__header,
          &__body {
            color: var(--el-color-white) !important;
            background: linear-gradient(to right, #2cdd7d, #189765);
          }
        }
      }
    }

    margin-bottom: $base-margin;
  }
</style>
